import React from 'react';
import { findFlagUrlByIso2Code } from 'country-flags-svg'

export const findLanguageByLanguageKey = (languageKey) => {
  return languages.find((item) => item.key === languageKey);
};

export const getLanguageDemoTextByLanguageKey = (languageKey) => {
  return languages.find((item) => item.key === languageKey).text;
}

export const getLanguageWordsByLanguageKey = (languageKey) => {
  return languages.find((item) => item.key === languageKey).words;
}

export const getLanguageSourceByLanguageKey = (languageKey) => {
  return languages.find((item) => item.key === languageKey).source;
}

export const sortedAndTranslateAllLanguages = (translate, allLanguages) => {
  const translatedLanguages = allLanguages.map((item) => {
    return {
      ...item,
      name: translate(`languages.${item.language}`),
    };
  });

  return translatedLanguages.sort((firstLanguage, secondLang) =>
    firstLanguage.name.localeCompare(secondLang.name),
  );
};

export const languages = [
  {
    language: 'english',
    displayAtPrefferedInForeingStep: true,
    key: 'en',
    Image: (props) => <img src={findFlagUrlByIso2Code('US')} {...props} />,
    text: 'English is the largest language by number of speakers, and the third most-spoken native language in the world, after Standard Chinese and Spanish. It is the most widely learned second language and is either the official language or one of the official languages in almost 60 sovereign states. There are more people who have learned it as a second language than there are native speakers. As of 2005, it was estimated that there were over 2 billion speakers of English',
    words: ['game', 'adventure', 'creative', 'challenge', 'joy', 'self-development', 'awesome', 'friendship'],
    source: 'https://en.wikipedia.org/wiki/English_language'
  },
  {
    language: 'spanish',
    displayAtPrefferedInForeingStep: true,
    key: 'es',
    Image: (props) => <img src={findFlagUrlByIso2Code('ES')} {...props} />,
    text: 'El español, como otras lenguas romances, es el resultado de siglos de evolución a partir del latín hablado (denominado latín vulgar) desde el siglo iii aproximadamente. Tras la caída del Imperio romano el latín vulgar de la Hispania romana se fue transformando y divergiendo de las otras variantes del latín que se hablaban en otras provincias del antiguo Imperio. Las transformaciones dieron lugar, tras una lenta evolución, a las distintas lenguas romances que existen hoy en día en diferentes partes de Europa.',
    words: ['juego', 'aventura', 'creativo', 'desafío', 'alegría', 'autodesarrollo', 'impresionante', 'amistad'],
    source: 'https://es.wikipedia.org/wiki/Idioma_español'
  },
  {
    language: 'italian',
    displayAtPrefferedInForeingStep: true,
    key: 'it',
    Image: (props) => <img src={findFlagUrlByIso2Code('IT')} {...props} />,
    text: 'È diffuso nelle comunità di emigrazione italiana, è ampiamente noto anche per ragioni pratiche in diverse aree geografiche ed è una delle lingue straniere più studiate nel mondo. Dal punto di vista storico, l\'italiano è una lingua basata sul fiorentino letterario usato nel Trecento.',
    words: ['gioco', 'avventura', 'creativo', 'sfida', 'gioia', 'autosviluppo', 'impressionante', 'amicizia'],
    source: 'https://it.wikipedia.org/wiki/Lingua_italiana'
  },
  {
    language: 'ukrainian',
    displayAtPrefferedInForeingStep: true,
    key: 'uk',
    Image: (props) => <img src={findFlagUrlByIso2Code('UA')} {...props} />,
    text: 'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae.',
    words: ['гра', 'пригода', 'креативний', 'виклик', 'радість', 'самовдосконалення', 'приголомшливий', 'дружба'],
    source: ''
  },
  {
    language: 'french',
    displayAtPrefferedInForeingStep: true,
    key: 'fr',
    Image: (props) => <img src={findFlagUrlByIso2Code('FR')} {...props} />,
    text: 'Le français est parlé, en 2018, sur tous les continents par environ 300 millions de personnes, 235 millions l\'emploient quotidiennement, et 90 millions. en sont des locuteurs natifs. En 2018, 80 millions d\'élèves et étudiants s\'instruisent en français dans le monde. Selon l\'Organisation internationale de la francophonie, il pourrait y avoir 700 millions de francophones sur Terre en 2050.',
    words: ['jeu', 'aventure', 'créatif', 'défi', 'joie', 'développement personnel', 'génial', 'amitié'],
    source: 'https://fr.wikipedia.org/wiki/Français'
  },
  {
    language: 'german',
    displayAtPrefferedInForeingStep: true,
    key: 'de',
    Image: (props) => <img src={findFlagUrlByIso2Code('DE')} {...props} />,
    text: 'Die Standardsprache mit ihren Standardvarietäten, Deutsch oder Hochdeutsch genannt, ist das Ergebnis bewusster sprachplanerischer Eingriffe. Das Standarddeutsche überspannt als Dachsprache den Großteil der Mundarten des Dialektkontinuums.',
    words: ['Spiel', 'Abenteuer', 'kreativ', 'Herausforderung', 'Freude', 'Selbstentfaltung', 'Fantastisch', 'Freundschaft'],
    source: 'https://de.wikipedia.org/wiki/Deutsche_Sprache'
  },
  {
    language: 'japanese',
    key: 'ja',
    Image: (props) => <img src={findFlagUrlByIso2Code('JP')} {...props} />,
    text: 'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae.',
    words: ['ゲーム', 'アドベンチャー', 'クリエイティブ', 'チャレンジ', '喜び', '自己啓発', 'すごい', '友情'],
    source: ''
  },
  {
    language: 'chinese',
    key: 'zh',
    Image: (props) => <img src={findFlagUrlByIso2Code('CN')} {...props} />,
    text: 'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae.',
    words: ['游戏', '冒险', '创意', '挑战', '快乐', '自我发展', '令人敬畏', '友谊'],
    source: ''
  },
  {
    language: 'russian',
    key: 'ru',
    Image: (props) => <img src={findFlagUrlByIso2Code('RU')} {...props} />,
    text: 'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae.',
    words: ['игра', 'приключение', 'творческий', 'вызов', 'радость', 'саморазвитие', 'удивительный', 'дружба'],
    source: ''
  },
  {
    language: 'portuguese',
    key: 'pt',
    Image: (props) => <img src={findFlagUrlByIso2Code('PT')} {...props} />,
    text: 'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae.',
    words: ['jogo', 'aventura', 'criativo', 'desafio', 'alegria', 'auto-desenvolvimento', 'fantástico', 'amizade'],
    source: ''
  },
  {
    language: 'dutch',
    key: 'nl',
    Image: (props) => <img src={findFlagUrlByIso2Code('NL')} {...props} />,
    text: 'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae.',
    words: ['game', 'avontuur', 'creatief', 'uitdaging', 'vreugde', 'zelfontplooiing', 'geweldig', 'vriendschap'],
    source: ''
  },
  {
    language: 'swedish',
    key: 'sv',
    Image: (props) => <img src={findFlagUrlByIso2Code('SE')} {...props} />,
    text: 'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae.',
    words: ['spel', 'äventyr', 'kreativt', 'utmaning', 'glädje', 'självutveckling', 'häftigt', 'vänskap'],
    source: ''
  },
  {
    language: 'norwegian',
    key: 'no',
    Image: (props) => <img src={findFlagUrlByIso2Code('NO')} {...props} />,
    text: 'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae.',
    words: ['spill', 'eventyr', 'kreativ', 'utfordring', 'glede', 'selvutvikling', 'Rått', 'vennskap'],
    source: ''
  },
  {
    language: 'polish',
    displayAtPrefferedInNativeStep: true,
    key: 'pl',
    Image: (props) => <img src={findFlagUrlByIso2Code('PL')} {...props} />,
    text: 'Język polski należy do gałęzi zachodniej w ramach języków słowiańskich, zaliczanych do indoeuropejskiej rodziny językowej. Jest blisko spokrewniony z językami słowackim i czeskim, z którymi tworzy zachodniosłowiańskie kontinuum dialektalne.',
    words: ['gra', 'przygodowa', 'kreatywna', 'wyzwanie', 'radość', 'samorozwój', 'niesamowita', 'przyjaźń'],
    source: 'https://pl.wikipedia.org/wiki/Język_polski'
  },
  {
    language: 'greek',
    key: 'el',
    Image: (props) => <img src={findFlagUrlByIso2Code('GR')} {...props} />,
    text: 'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae.',
    words: ['παιχνίδι', 'περιπέτεια', 'δημιουργικό', 'πρόκληση', 'χαρά', 'αυτο-ανάπτυξη', 'φοβερό', 'φιλία'],
    source: ''
  },
  {
    language: 'danish',
    key: 'da',
    Image: (props) => <img src={findFlagUrlByIso2Code('DK')} {...props} />,
    text: 'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae.',
    words: ['spil', 'eventyr', 'kreativ', 'udfordring', 'glæde', 'selvudvikling', 'fantastisk', 'venskab'],
    source: ''
  },
  {
    language: 'czech',
    key: 'cs',
    Image: (props) => <img src={findFlagUrlByIso2Code('CZ')} {...props} />,
    text: 'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae.',
    words: ['hra', 'dobrodružství', 'kreativní', 'výzva', 'joy', 'seberozvoj', 'úžasné', 'přátelství'],
    source: ''
  },

  {
    language: 'hungarian',
    key: 'hu',
    Image: (props) => <img src={findFlagUrlByIso2Code('HU')} {...props} />,
    text: 'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae.',
    words: ['játék', 'kaland', 'kreatív', 'kihívás', 'öröm', 'önfejlesztés', 'fantasztikus', 'barátság'],
    source: ''
  },
  {
    language: 'romanian',
    key: 'ro',
    Image: (props) => <img src={findFlagUrlByIso2Code('RO')} {...props} />,
    text: 'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae.',
    words: ['joc', 'aventură', 'creativ', 'provocare', 'bucurie', 'auto-dezvoltare', 'minunat', 'prietenie'],
    source: ''
  },
  {
    language: 'finnish',
    key: 'fi',
    Image: (props) => <img src={findFlagUrlByIso2Code('FI')} {...props} />,
    text: 'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae.',
    words: ['peli', 'seikkailu', 'luova', 'haaste', 'ilo', 'itsensä kehittäminen', 'mahtava', 'ystävyys'],
    source: ''
  },
  {
    language: 'bulgarian',
    key: 'bg',
    Image: (props) => <img src={findFlagUrlByIso2Code('BG')} {...props} />,
    text: 'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae.',
    words: ['игра', 'приключение', 'творчески', 'предизвикателство', 'радост', 'саморазвитие', 'страхотен', 'приятелство'],
    source: ''
  },
  {
    language: 'estonian',
    key: 'et',
    Image: (props) => <img src={findFlagUrlByIso2Code('EE')} {...props} />,
    text: 'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae.',
    words: ['mäng', 'seiklus', 'loominguline', 'väljakutse', 'rõõm', 'eneseareng', 'vinge', 'sõprus'],
    source: ''
  },
  {
    language: 'lithuanian',
    key: 'lt',
    Image: (props) => <img src={findFlagUrlByIso2Code('LT')} {...props} />,
    text: 'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae.',
    words: ['žaidimas', 'nuotykių', 'kūrybinis', 'iššūkis', 'džiaugsmas', 'saviugda', 'nuostabus', 'draugystė'],
    source: ''
  },
  {
    language: 'latvian',
    key: 'lv',
    Image: (props) => <img src={findFlagUrlByIso2Code('LV')} {...props} />,
    text: 'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae.',
    words: ['spēle', 'piedzīvojumu', 'radošs', 'izaicinājums', 'prieks', 'pašattīstība', 'satriecošs', 'draudzība'],
    source: ''
  },
  {
    language: 'slovakian',
    key: 'sk',
    Image: (props) => <img src={findFlagUrlByIso2Code('SK')} {...props} />,
    text: 'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae.',
    words: ['hra', 'dobrodružstvo', 'kreatívny', 'výzva', 'joy', 'sebarozvoj', 'úžasné', 'priateľstvo'],
    source: ''
  },
  {
    language: 'slovenian',
    key: 'sl',
    Image: (props) => <img src={findFlagUrlByIso2Code('SI')} {...props} />,
    text: 'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae.',
    words: ['igra', 'pustolovščina', 'ustvarjalni', 'izziv', 'veselje', 'samorazvoj', 'super', 'prijateljstvo'],
    source: ''
  },
];
